import { Canvas } from '@react-three/fiber';
import { Model } from './resources/Amrit';
import { Suspense } from 'react';
import { OrbitControls } from '@react-three/drei';
export default function App() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#282a36",
        height: '100vh',
      width: "100vw",
      overflow: "hidden",
      flexDirection: "column"
      }}
    >
    <div
      style={{
        color: "#FFFFFF",
        fontFamily: "Dancing Script",
        fontSize: "5rem",
      }}
    >
      Hi this is
    </div>
    <div style={{
      background: "#282a36",
    }}>
      <Canvas 
        style={{
          width: "100vw",
          height: "30rem",
          background: "transparent",
          zIndex: 2,
          marginLeft: "1.5rem"
        }}  
        linear
        camera={{
          position: [-0.2, 0, 2]
        }}
      >
        <ambientLight intensity={6.5} />
        <spotLight intensity={1} angle={0.1} penumbra={1} position={[10, 15, 5]} castShadow color={"#FFFFFF"}/>
        <OrbitControls enableZoom={false}/>
        <Suspense fallback={null}>
          <Model /> 
        </Suspense>
      </Canvas>
    </div>
    </div>
  );
}

